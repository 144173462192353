function addHeadings(data) {
    let row = '';
    let counter = 0;
    let amount = Object.keys(data).length;
    for (var value in data) {
        row += value + ((counter + 1 < amount) ? ',' : '\r\n');
        counter++;
    }
    return row;
}

function generateCsvData(data) {
    let csv = addHeadings(data[0]);

    // Loop the array of objects
    for(let i = 0; i < data.length; i++){
        const keysAmount = Object.keys(data[i]).length;
        const row = data[i];
        let keysCounter = 0;
    
        // Loop each property of the object
        for (let column in row) {
            const notRowEnd = keysCounter + 1 < keysAmount;
            csv += (row[column] + (notRowEnd ? ',' : '\r\n' ));
            keysCounter++;
        }
    
        keysCounter = 0;
    }

    return csv;
}

export default generateCsvData;