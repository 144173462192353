import Vue from 'vue';
import Vuex from 'vuex';
import authentication from '@/store/modules/authentication.js';
import location from '@/store/modules/location.js';
import organization from '@/store/modules/organization.js';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    authentication: authentication,
    location: location,
    organization: organization,
  }
});