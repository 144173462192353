<template>
  <nav>
    <v-app-bar
      color="blue-grey darken-2"
      dark
      flat
      app
      elevation="0"
    >
      <v-app-bar-nav-icon @click.stop="navOpen = !navOpen" />
    </v-app-bar>
    <v-navigation-drawer 
      v-model="navOpen"
      app
      floating
      :permanent="navOpen"
    >
      <v-list color="blue-grey lighten-5" class="nav-list">
        <v-list-item class="blue-grey darken-2" dark>
          <v-list-item-action link>
              <v-icon @click.stop="navOpen = !navOpen">mdi-chevron-left</v-icon>
          </v-list-item-action>
          <v-list-item-content class="nav-list-header-content">
            <v-list-item-title class="text-h6">IDS</v-list-item-title>
            <v-list-item-subtitle>WebViewer v2.0</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link>
          <v-list-item-content @click="$router.push({name: 'Dashboard'})">
              <v-list-item-title class="secondary--text white--text" >Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link>
          <v-list-item-content @click="logout">
              <v-list-item-title class="secondary--text white--text" >Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('authentication');

export default {
    methods: {
        ...mapActions(['logout'])
    },
    data: () => ({
        navOpen: false,
    }),
}
</script>

<style lang="scss" scoped>
.nav-list {
  height: 100vh;
  padding: 0;
  overflow: hidden;
  box-shadow: 0 0 8px rgba(0,0,0,0.3);

  &-header-content {
    padding: 11px 0;
  }
}
</style>