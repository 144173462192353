<template>
    <v-list-item-content>
        <v-list>
          <v-subheader>Locations:</v-subheader>
          <template v-for="(location, i) in locations">
            <v-divider class="mb-0 list-divider" :key="i" />
            <v-list-item @click="selectLocation(location.node.id)" :key="location.node.name + i">
              <v-list-item-content>
                <v-list-item-title>
                  {{location.node.name}}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{location.node.address.streetLine1}}<br>
                  {{location.node.address.city}},
                  {{location.node.address.state}}
                  {{location.node.address.postalCode}}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
    </v-list-item-content>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('location');
export default {
    props: {
        locations: {
            type: Array,
            required: true,
        }
    },
    methods: {
      ...mapActions(['resetActiveLocation']),
      async selectLocation(id) {
        debugger;
        // this.resetActiveLocation(id);
        this.$router.push({name: 'Location', params: {id}})
      }
    }
}
</script>

<style lang="scss" scoped>
.list-divider {
  flex: none !important;
}
</style>
