<template>
    <v-container v-if="activeLocation" fluid class="pa-0 d-flex">
        <!-- TODO: this needs to be a new component -->
        <v-container class="sidebar" v-if="activeOrganization">
            <div class="sidebar-button-container">
                <v-btn @click="$router.push({name: 'Organization', params: {id: activeOrganization.id}})" x-small>
                    &laquo; Back to {{activeOrganization.name}}
                </v-btn>

                <v-btn color="green darken-1" class="white--text" @click="goToUploadFiles">
                    <v-icon left>
                        mdi-arrow-up-bold-box-outline
                    </v-icon>
                    Upload Files
                </v-btn>
            </div>

            <v-divider class="mt-4" />

            <h1>{{activeLocation.name}}</h1>

            <p class="subtitle-1">
                {{activeLocation.address.streetLine1}}
                {{activeLocation.address.city}},
                {{activeLocation.address.state}}
                {{activeLocation.address.postalCode}}
            </p>

            <v-divider class="mt-4" />
            <!-- TODO: this needs to be a new component -->
            <v-list>
                <h3 class="pt-4 pl-4">Projects</h3>
                <v-list-item v-for="project in activeLocation.projects.edges" :key="'project'+project.id">
                    <v-list-item-content class="border-bottom">
                        <v-list-item-title>
                            {{project.node.name}}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <div class="py-2">{{project.node.description}}</div>
                            <v-chip color="primary" small outlined>{{project.node.status}}</v-chip>
                        </v-list-item-subtitle>
                        
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <!-- TODO: this needs to be a new component -->
            <div class="media-thumbnail-list" v-if="activeLocation.images">
                <h3 class="media-thumbnail-list-title pt-4 pl-4">Media <small>({{activeLocation.images.length}} Total)</small></h3>
                <div v-for="item in activeLocation.images" :key="'media'+item.image.id" class="media-thumbnail-list-item">
                    <v-img class="media-thumbnail-list-image" :alt="item.image.description" :src="item.image.sourceImage.url" />
                </div>
            </div>
            <div v-else>
                <v-progress-circular
                    indeterminate
                    color="light-blue darken-1"
                    class="mr-2"
                />
                Loading Media ...
            </div>

            <!-- <pre>{{activeLocation}}</pre> -->

        </v-container>
        <div class="map">
            <l-map
                :zoom="zoom"
                :center="locationPosition"
                @update:zoom="zoomUpdated"
                @update:center="centerUpdated"
                @update:bounds="boundsUpdated"
            >
                <l-control-layers position="topleft" />

                <l-marker :lat-lng="locationPosition" />
                
                <l-circle-marker
                    v-for="(marker, i) in locationMediaMarkers"
                    :key="'marker-' + i"
                    :lat-lng="marker.position"
                    :color="marker.color"
                    :radius="4"
                    :weight="2"
                />

                <l-tile-layer
                    v-for="tile in tiles"
                    :key="tile.name"
                    :name="tile.name"
                    :visible="tile.visible"
                    :url="tile.url"
                    :attribution="tile.attribution"
                    layer-type="base"/>
            </l-map>
        </div>
    </v-container>
    <div v-else class="text-center p-4">
        Please wait while we load your location ...
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters: mapGettersLocation, mapActions: mapActionsLocation } = createNamespacedHelpers("location");
const { mapGetters: mapGettersOrganization, mapActions: mapActionsOrganization } = createNamespacedHelpers("organization");
import {LMap, LTileLayer, LControlLayers, LCircleMarker} from 'vue2-leaflet';

export default {
    components: {
        LMap,
        LTileLayer,
        LControlLayers,
        LCircleMarker,
    },
    async created() {
        await this.setActiveLocation(this.locationId);
        this.getLocationMedia(this.locationId);

        if (!this.activeOrganization) {
            await this.getOrganization(this.activeLocation.organization.id);
        }

        this.getLocationImages(this.locationId);
    },
    computed: {
        ...mapGettersLocation(['activeLocation']),
        ...mapGettersOrganization(['activeOrganization']),
        locationPosition() {
            if (!this.activeLocation) return [45, -92];
            return [this.activeLocation.position.latitude, this.activeLocation.position.longitude];
        },
        locationMediaMarkers() {
            if (!this.activeLocation || !this.activeLocation.media) return [];

            return this.activeLocation.media.map(item => {
                return {
                    position: [item.latitude, item.longitude],
                    color: (item.__typename === 'UserPhoto') ? 'red' : 'green',
                };
            });
        }
    },
    methods: {
        ...mapActionsLocation(['getLocationMedia', 'setActiveLocation', 'getLocationImages']),
        ...mapActionsOrganization(['getOrganization']),
        zoomUpdated (zoom) {
            this.zoom = zoom;
        },
        centerUpdated (center) {
            this.center = center;
        },
        boundsUpdated (bounds) {
            this.bounds = bounds;
        },
        goToUploadFiles() {
            this.$router.push({name: 'Upload', query: {id: this.activeOrganization.id, location: this.activeLocation.name}});
        }
    },
    data () {
        return {
            locationId: this.$route.params.id,
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            zoom: 18,
            bounds: null,
            tiles: [
                {
                    name: 'OpenStreetMap',
                    visible: true,
                    attribution:
                        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                },
                {
                    name: 'OpenTopoMap',
                    visible: false,
                    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
                    attribution:
                        'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
                },
            ],
        };
    }
}
</script>
<style lang="scss" scoped>
.map {
    height: calc(100vh - 64px);
    width: calc(100% - 460px);
}
.sidebar {
    background: white;
    padding: 1rem;
    width: 460px;
    height: calc(100vh - 64px);
    position: relative;
    z-index: 100;
    box-shadow: 0 0 8px rgba(0,0,0,0.3);
    z-index: 1000;
    overflow: auto;
}
.sidebar-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.border-bottom {
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
}
.media-thumbnail-list {
    width: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &-title {
        width: 400px;
        margin-bottom: 1rem;
    }

    &-item {
        display: block;
        width: 74px;
        height: 74px;
        border: 1px solid #e9e9e9;
        margin: 0 4px 8px;
    }
}
</style>