<template>
    <uppy-dashboard v-if="uppy" :uppy="uppy" />
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('authentication');

// import runtime from 'serviceworker-webpack-plugin/lib/runtime';
// if ('serviceWorker' in navigator) {
//   const registration = runtime.register();
//   console.log(registration)
// }
// import('@uppy/golden-retriever/lib/ServiceWorker')
// import GoldenRetriever from '@uppy/golden-retriever'

import { Dashboard } from '@uppy/vue';
import Dash from '@uppy/dashboard';
import Tus from '@uppy/tus';
import Uppy from '@uppy/core';

let userToken;

export default {
  components: {
    'uppy-dashboard': Dashboard,
  },
  props: {
    bucket: {
      type: String,
      required: true,
    }
  },
  created() {
    userToken = this.userToken;
    this.initializeUppy(this.bucket);
  },
  watch: {
    bucket() {
      this.initializeUppy(this.bucket);
    }
  },
  computed: {
    ...mapGetters(['userToken']),
  },
  methods: {
    uppyListeners() {
      this.uppy.on('file-added', (file) => {
        this.$emit('fileadded', file.name)
      });
      this.uppy.on('file-removed', (file) => {
        this.$emit('fileremoved', file.name)
      });
      this.uppy.on('upload', () => {
        console.log('uploading...')
        this.$emit('uploading', true);
      });
      this.uppy.on('upload-success', (file, response) => {
        console.log('upload success...')
        console.log(file, response);
      });
      this.uppy.on('upload-error', () => {
        console.log('upload error...')
      });
      this.uppy.on('complete', (data) => {
        console.log('upload complete: ',data);
        if (!data.failed.length) this.$emit('complete');
      });
    },
    initializeUppy(bucket) {
      const endpoint = process.env.VUE_APP_TUS_SERVER
      this.uppy = new Uppy({
          width: 900,
          height: 200,
          id: Math.random().toString(36).substr(2, 5),
          restrictions: {
            allowedFileTypes: ['.jpg','.jpeg'],
          },
      })
      // .use(GoldenRetriever, { serviceWorker: true })
      .use(Dash, {
          width: 900,
          height: 200,
      })
      .use(Tus, {
          limit: 5,
          endpoint,
          resume: true,
          retryDelays: [0, 1000, 3000, 5000],
          chunkSize: 5*1024*1024, // chunking is required unless you're using Falcon
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Upload-Prefix": bucket,
          },
      });

      this.uppyListeners();
    },
  },
  beforeDestroy () {
    this.uppy.close();
  },
  data() {
      return {
        uppy: null,
        uppyDragDropOptions: {
            note: "A helpful note directing a user on what to do. This can be dynamically generated",
        }
      }
  }
}
</script>

<style>
  .uppy-Dashboard-inner {
    margin: 0 auto;
  }
  .uppy-Dashboard-AddFiles-info {
    display: none !important;
  }
  .uppy-DashboardContent-bar,
  .uppy-StatusBar,
  .uppy-Dashboard-Item-progress,
  .uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progress {
    z-index: 1 !important;
  }
  .uppy-size--md .uppy-Dashboard-Item-action--remove {
    z-index: 7;
  }
  .uppy-Dashboard-inner {
    height: 350px !important;
  }
  /* hide all ways to cancel an in progress upload */
  .uppy-u-reset[title="Cancel"],
  .uppy-DashboardContent-back,
  .uppy-DashboardContent-addMore,
  .uppy-Dashboard-Item.is-inprogress .uppy-u-reset[title="Remove file"] {
    display: none !important;
  }
</style>