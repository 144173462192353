<template>
  <v-app>
    <navigation v-if="userToken" />
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters } = createNamespacedHelpers('authentication');
import Navigation from '@/components/navigation.vue';

export default {
  name: 'App',
  components: {
    Navigation
  },
  created() {
    this.checkUserToken();
  },
  computed: {
    ...mapGetters(['userToken']),
  },
  methods: {
    ...mapActions(['checkUserToken']),
  },
};
</script>