import axios from 'axios';
import Router from '@/router';

const axiosOptions = {
    headers: {
        'x-client-id': 'IDS_Web_Client',
    },
};
const rubyEndpoint = process.env.VUE_APP_RUBY_API;

console.log('process.env: ', process.env)
console.log('rubyEndpoint: ', rubyEndpoint)

export default {
    namespaced: true,
    state: {
        user: null,
        userToken: null,
    },
    getters: {
      user(state) {
        return state.user;
      },
      userToken(state) {
        if (localStorage.getItem('IDSaccessToken')) {
          state.userToken = localStorage.getItem('IDSaccessToken');
        }
        return state.userToken;
      },
    },
    actions: {
      async login({ commit }, user) {
        try {
          const response = await axios.post(`${rubyEndpoint}/api/v1/authenticate`, user, axiosOptions);
          if (response.data.token)
            commit('SET_USER_TOKEN', response.data.token);

          return response.data.token;
        } catch (error) {
          console.error('login error: ', error)
        }
      },
      logout({ commit }) {
        commit('CLEAR_USER');
        Router.push({name: 'login'});
      },
      checkUserToken({commit}) {
        if (localStorage.getItem('IDSaccessToken')) {
          commit('SET_USER_TOKEN', localStorage.getItem('IDSaccessToken'));
        }
      },
    },
    mutations: {
      SET_USER_TOKEN (state, token) {
        state.userToken = token;
        localStorage.setItem('IDSaccessToken', token);
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      },
      CLEAR_USER(state) {
        state.user = null;
        state.userToken = null;
        localStorage.removeItem('IDSaccessToken');
      }
    },
};