<template>
    <section class="locations" v-if="locations">
        <div class="header pa-8" v-if="activeOrganization">
            <h2>{{activeOrganization.name}}</h2>
            <v-btn color="green darken-1" class="white--text" @click="goToUploadFiles">
                <v-icon left>
                    mdi-arrow-up-bold-box-outline
                </v-icon>
                Upload Files
            </v-btn>
        </div>
        <location-list class="pa-4" :locations="locations" v-if="locations" />
    </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters: mapGettersLocation, mapActions: mapActionsLocation } = createNamespacedHelpers("location");
const { mapGetters: mapGettersOrganization, mapActions: mapActionsOrganization } = createNamespacedHelpers("organization");
import LocationList from '@/components/locationList.vue';

export default {
    components: {
        LocationList,
    },
    async created() {
        if (!this.activeOrganization || this.activeOrganization.id !== this.organizationId) {
            await this.getOrganization(this.organizationId); 
        } else if (!this.organizationId) {
            this.$router.push({name: 'Dashboard'});
            return;
        }
        await this.getLocations(this.organizationId);
    },
    computed: {
        ...mapGettersLocation(['locations', 'activeLocation']), 
        ...mapGettersOrganization(['activeOrganization']), 
    },
    methods: {
        ...mapActionsLocation(['getLocations', 'setActiveLocation']),
        ...mapActionsOrganization(['getOrganization']),
        goToUploadFiles() {
            this.$router.push({name: 'Upload', query: {id: this.activeOrganization.id}});
        }
    },
    data() {
        return {
            organizationId: this.$route.params.id,
        }
    }

}
</script>

<style lang="scss" scoped>
    .header {
        border-bottom: 1px solid #ccc;
    }
</style>