<template>
    <div v-if="loading" class="text-center p-4">
        <v-progress-circular
            indeterminate
            color="black"
            class="mt-4"
        />
    </div>
    <v-container v-else>
        <h1>Media Uploader Tool</h1>
        <v-alert
            border="left"
            color="grey lighten-4"
            dismissible
        >
            1. Select a Location and Project you have media files for<br> 
            2. Choose the type of Media Files you are uploading<br>
            3. Upload the media files<br>
            4. Update any information such as Area and Level for each image<br>
            5. Save the updates to IDS<br>
        </v-alert>
        <hr>
        <strong>Organization:</strong> {{activeOrganization.name}}
        <v-container class="mt-4">
            <v-alert v-if="locationQuery"
                border="bottom"
                color="blue"
                dense
                elevation="1"
                text
                icon="mdi-magnify"
                type="info"
            >
                Search by: {{locationQuery}}
            </v-alert>
            <v-autocomplete
                id="locationProjects"
                ref="locationProjects"
                v-if="locationProjects && locationProjects.length"
                filled
                outlined
                label="Find your Project and Location"
                :items="locationProjects"
                :disabled="uploadStarted"
                @change="chooseLocationProject"
                item-text="name"
                item-value="value"
                elevation-24
            />

            <v-alert
                v-else
                border="bottom"
                color="light-blue lighten-5"
            >
                <v-progress-circular
                    indeterminate
                    color="light-blue darken-1"
                    class="mr-4"
                />
                Please wait while your locations load ...
            </v-alert>

            <v-alert
                v-if="loadingProject"
                border="bottom"
                color="light-blue lighten-4"
            >
                <v-progress-circular
                    indeterminate
                    color="light-blue darken-1"
                    class="mr-4"
                />
                Loading Project ...
            </v-alert>

            <v-select
                v-else-if="project && mediaTypes.length"
                v-model="fileType"
                label="Choose a File Type"
                :items="mediaTypes"
                :disabled="uploadStarted"
            />

            <uploader 
                v-if="uploadLocation"
                v-show="showUploader"
                :key="actionDateTime"
                :bucket="uploadLocation" 
                @fileadded="setMediaPrefix"
                @fileremoved="removeFile"
                @uploading="setUploadStarted" 
                @complete="complete"
                @reset="resetFiles"
            />

            <v-alert
                v-if="uploadIsComplete && metaDataState === 'Started'"
                border="bottom"
                color="green lighten-4"
            >
                <strong>Your files were successfully uploaded.</strong>
                <br>
                Please save the file data below before uploading more files.
            </v-alert>

            <meta-data-wizard 
                ref="wizard"
                v-if="metaDataState === 'Started'" 
                :location="activeLocation"
                :files="filesAdded"
                :files-added="filesAdded"
                :generate-csv="generateCsv"
                :media-type="selectedMediaType.mediaType"
                :metadata-types="metaDataTypes"
                :metadata-options="activeLocation.metadataTypes"
                :default-metadata="defaultMetaDataValues"
                :image-prefix="imagePrefix"
                :image-file-type="imageFiletype"
                :save="saveMetaData"
                @update="updateFiles"
                @resetFiles="resetMetadata"
            />
        </v-container>
    </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import MetaDataWizard from '@/components/imageMetaDataWizard.vue';
const { mapGetters: mapGettersLocation, mapActions: mapActionsLocation } = createNamespacedHelpers("location");
const { mapGetters: mapGettersOrganization, mapActions: mapActionsOrganization } = createNamespacedHelpers("organization");
import Uploader from '@/components/uppyUploader.vue';

export default {
    components: {
        Uploader,
        MetaDataWizard,
    },
    async created() {
        if (!this.activeOrganization && !this.organizationId) {
            return this.$router.push({name: 'Dashboard'});
        }
        if (!this.activeOrganization || ((this.activeOrganization && this.activeOrganization.id) && this.activeOrganization.id !== this.organizationId)) {
            await this.getOrganization(this.organizationId); 
        }
            
        await this.getLocations(this.organizationId);
        await this.getOrganizationProjects(this.organizationId);
    },
    beforeDestroy() {
        this.resetLocations();
    },
    watch: {
        fileType(newValue, oldValue) {
            if (oldValue && oldValue !== newValue && this.uploadIsComplete && this.metaDataState === 'Started') {
                const path = `${this.activeLocation.name}-${this.activeLocation.id}/${this.project.name}-${this.project.id}/${oldValue}/${this.actionDateTime}`;
                this.$refs.wizard.saveData(this.projectMediaTypes.find(type => type.name === oldValue).type, path);
            }
        }
    },
    computed: {
        ...mapGettersLocation(['locations', 'activeLocation']), 
        ...mapGettersOrganization(['activeOrganization', 'projects']),
        files() {
            const files = [];
            if (!this.fileType || !this.activeLocation) return files;

            this.activeLocation.placeholders.map(item => {
                if (item.mediaType.replace(/\s+/g, '').toLowerCase() === this.interpretedFileType) {
                    files.push(item);
                }
            });

            return files;
        },
        interpretedFileType() {
            return (this.fileType === 'DSLR Unstitched') ? 'photo_360'
                : (this.fileType === 'DSLR HD') ? 'photo_hd'
                : this.fileType.replace(/\s+/g, '').toLowerCase();
        },
        loading() {
            return !this.locations || !this.activeOrganization;
        },
        generateCsv() {
            if (!this.fileType) return false;
            return this.projectMediaTypes.find(type => type.name === this.fileType).type === 'custom';
        },
        locationProjects() {
            if (!this.projects) return null;
            let result = [];

            this.projects.map(project => {
                result.push({
                    name: `${project.node.name} - ${project.node.location.name}`,
                    value: {
                        location: project.node.location.id,
                        project: {
                            id: project.node.id,
                            name: project.node.name,
                        },
                    },
                });
            });
            
            return result;
        },
        metaDataTypes() {
            if (this.selectedMediaType.metadata.length) {
                return this.selectedMediaType.metadata;
            }
            if (!this.files || !this.files.length) {
                return this.defaultMetaDataValues;
            }

            const types = [];
            this.files.map(item => {
                item.metadata.map(type => {
                    if (!types.some(t => t.type === type.type)) {
                        types.push(type);
                    }
                });
            });
            return types;
        },
        mediaTypes() {
            if (!this.projectMediaTypes) return [];
            return this.projectMediaTypes.map(type => {
                return type.name;
            });
        },
        showUploader() {
            return this.activeLocation && this.project && this.fileType && !this.uploadIsComplete;
        },
        uploadLocation() {
            if (!this.activeLocation || !this.project || !this.fileType) return null;
            return `${this.activeLocation.name}-${this.activeLocation.id}/${this.project.name}-${this.project.id}/${this.fileType}/${this.actionDateTime}`;
        },
        selectedMediaType() {
            return this.projectMediaTypes.find(type => type.name === this.fileType);
        },
        projectMediaTypes() {
            if (!this.project) return null;
            return this.project.mediaFileTypes;
        },
    },
    methods: {
        ...mapActionsLocation(['getLocations', 'getProject', 'resetLocations', 'setActiveLocation', 'setActiveLocationMetadata', 'setActiveLocationPlaceholders', 'saveActiveLocationMetadata', 'uploadComplete']),
        ...mapActionsOrganization(['getOrganization', 'getOrganizationProjects']),
        setUploadStarted(v) {
            this.uploadIsComplete = false;
            this.uploadStarted = v;
            this.metaDataState = 'Started';
        },
        async chooseLocationProject(value) {
            this.loadingProject = true;
            let location = this.locations.find(location => location.node.id === value.location).node;
            await this.setActiveLocation(location.id);
            this.project = await this.getProject(value.project.id);
            this.loadingProject = false;
        },
        setMediaPrefix(filename) {
            const fileParts = this.getFileParts(filename);
            const filetype = fileParts[fileParts.length-1].split('.');
            this.imagePrefix = fileParts[0];
            this.imageFiletype = `.${filetype[filetype.length-1]}`;
            this.filesAdded.push(filename);
        },
        removeFile(filename) {
            // do not remove files if the upload was completed and the metadata has not started
            if (!this.uploadIsComplete && this.metaDataState !== 'Started') {
                this.filesAdded.splice(this.filesAdded.findIndex(file => file === filename), 1);
            }
        },
        getFileParts(file) {
            return (file.indexOf('_') > -1) ? file.split('_')
                : (file.indexOf(' ') > -1) ? file.split(' ')
                : file.split('-');
        },
        async complete() {
            this.uploadIsComplete = true;
            this.uploadStarted = false;
            const mediaType = this.selectedMediaType.type;
            const mediaTypeId = this.selectedMediaType.typeId;

            await this.uploadComplete({files: this.filesAdded.map(f => { return f.name }), projectId: this.project.id, s3: this.uploadLocation, mediaType: mediaType, mediaTypeId: mediaTypeId });

            if (this.metaDataState === 'Saved') this.resetMetadata();
        },
        saveMetaData(csvBlob, filename, path) {
            return new Promise(resolve => {
                const data = {
                    csvBlob: csvBlob,
                    s3Path: path || this.uploadLocation,
                    filename: (filename || this.selectedMediaType.type) + '.csv',
                    files: this.filesAdded,
                };

                this.saveActiveLocationMetadata(data).then(result => {
                    resolve(result);
                    if (this.uploadIsComplete) {
                        this.uploadIsComplete = false;
                        this.actionDateTime = this.$moment().format('MM-DD-YYYY hh:mm:ss a');
                        this.resetFiles();
                    }
                });
            });
        },
        async resetMetadata() {
            if (this.uploadIsComplete) {
                this.filesAdded = [];
                this.metaDataState = 'Not Started';
                this.uploadIsComplete = false; // reset this back to it's initial value: the user flow is complete
                this.actionDateTime = this.$moment().format('MM-DD-YYYY hh:mm:ss a');
            } else {
                this.metaDataState = 'Saved';
            }
        },
        resetFiles() {
            this.filesAdded = [];
            this.uploadStarted = false;
        },
        updateFiles(files) {
            this.filesAdded = files;
        },
    },
    data() {
        return {
            actionDateTime: this.$moment().format('MM-DD-YYYY hh:mm:ss a'),
            loadingProject: false,
            project: null,
            fileType: null,
            filesAdded: [],
            imagePrefix: 'img',
            imageFiletype: null,
            uploadStarted: false,
            uploadIsComplete: false,
            metaDataState: 'Not Started',
            organizationId: this.$route.query.id,
            locationQuery: this.$route.query.location,
            defaultMetaDataValues: [{
                name: 'Level',
                value: 'Aerial',
                type: 'level',
            }],
        }
    }
}
</script>

<style>

</style>