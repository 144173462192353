<template>
  <section class="image-metadata-wizard">
    <v-alert border="top" color="light-blue lighten-4" class="wizard-info-alert">
        Please confirm the information associated with the images you've uploaded.
        Once you have confirmed the information, please SAVE it to the Project below.
    </v-alert>

    <div class="pa-6 groups" v-if="imageGroups.length">
        <h2 class="mb-2">Grouped Images</h2>
        <template v-for="(group, i) in imageGroups">
            <v-alert
                :key="group.verifiedNumber + i" 
                color="blue darken-2"
                class="mb-0"
                dense
                outlined
                @click="expandGroup(group)"
            >
                <v-icon
                    large
                    color="blue darken-2"
                    v-if="!group.files[0].expanded"
                >
                    mdi-chevron-down
                </v-icon>
                <v-icon
                    large
                    color="blue darken-2"
                    v-else
                >
                    mdi-chevron-up
                </v-icon>
                Verified Image Number added in capture for 360 Panoramic image: {{group.verifiedNumber}} <small>({{group.files.length}} Files)</small>
            </v-alert>
            <v-data-table
                :key="'table ' + group.verifiedNumber"
                :headers="headers"
                :items="group.files"
                :items-per-page="24"
                item-key="name"
                class="elevation-1 mb-4 metadata-table"
                hide-default-footer
            >
                <template v-slot:body="{ items, headers }">
                    <tbody>
                        <!-- v-show="showGroupRow(group, idx)" -->
                        <tr v-for="(item, idx) in items" :key="idx" :class="{'yellow lighten-5': idx === 0}" v-show="showGroupRow(item.expanded, idx)">
                            <td v-for="(header,key) in headers" :key="key" class="metadata-table-cell" :class="{'lime lighten-3': !cellDisabled(header.disabled, idx)}">
                                <span v-if="key < 1">
                                    <v-text-field
                                        class="d-inline-flex"
                                        v-model="items[idx][header.type]"
                                        single-line
                                        disabled
                                    />
                                </span>
                                <v-select 
                                    v-else-if="hasOptions(items, header.type)"
                                    class="cell-select-box"
                                    item-text="name"
                                    item-value="name"
                                    :items="hasOptions(items, header.type)"
                                    :value="items[0].metadataValues[header.type]"
                                    :disabled="cellDisabled(header.disabled, idx)"
                                    @change="changeCellValue($event, header.type, item.name)"
                                />
                                <v-text-field
                                    v-else
                                    label="Edit"
                                    single-line
                                    :value="items[0][header.type]"
                                    :disabled="cellDisabled(header.disabled, idx)"
                                    @keyup="changeCellValue($event, header.value, item.name)"
                                />
                                <v-icon
                                    v-if="idx === 0 && key > 1"
                                    class="mr-2 edit-metadata"
                                    small
                                    @click="editItem(key)"
                                >
                                    mdi-pencil
                                </v-icon>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>
        </template>
    </div>

    <div class="pa-6 groups" v-if="ungroupedImages.length">
        <h2 class="mb-2">Images</h2>
        <v-data-table
            v-for="(image, i) in ungroupedImages"
            :key="'table ungrouped' + i"
            :headers="headers"
            :items="[image]"
            :items-per-page="24"
            item-key="name"
            class="elevation-1 mb-4 metadata-table"
            hide-default-footer
        >
            <template v-slot:body="{ items, headers }">
                <tbody>
                    <!-- v-show="showGroupRow(group, idx)" -->
                    <tr v-for="(item, idx) in items" :key="idx" :class="{'yellow lighten-5': idx === 0}" v-show="showGroupRow(item.expanded, idx)">
                        <td v-for="(header,key) in headers" :key="key" class="metadata-table-cell" :class="{'lime lighten-3': !cellDisabled(header.disabled, idx)}">
                            <span v-if="key < 1">
                                <v-text-field
                                    class="d-inline-flex"
                                    v-model="items[idx][header.type]"
                                    single-line
                                    disabled
                                />
                            </span>
                            <v-select 
                                v-else-if="hasOptions(items, header.type)"
                                class="cell-select-box"
                                item-text="name"
                                item-value="name"
                                :items="hasOptions(items, header.type)"
                                :value="items[0].metadataValues[header.type]"
                                :disabled="cellDisabled(header.disabled, idx)"
                                @change="changeCellValue($event, header.type, item.name)"
                            />
                            <v-text-field
                                v-else
                                label="Edit"
                                single-line
                                :value="items[0][header.type]"
                                :disabled="cellDisabled(header.disabled, idx)"
                                @keyup="changeCellValue($event, header.value, item.name)"
                            />
                            <v-icon
                                v-if="idx === 0 && key > 1"
                                class="mr-2 edit-metadata"
                                small
                                @click="editItem(key)"
                            >
                                mdi-pencil
                            </v-icon>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>
    </div>

    <v-btn class="save-button" v-if="save" color="primary" @click="saveData()" elevation-2>
        Save to IDS
    </v-btn>

    <v-dialog v-model="showSaveSuccess" width="500" class="modal">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Data Saved
        </v-card-title>

        <v-card-text class="pt-6">
          The Location Metadata has been saved
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" text @click="complete">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import generateCSV from '@/components/utilities/csvGenerator';

export default {
    props: {
        files: {
            type: Array,
            required: true,
        },
        generateCsv: {
            type: Boolean,
            default: false,
        },
        metadataTypes: {
            type: Array,
            default() { return[]; },
        },
        mediaType: {
            type: String,
            required: true,
        },
        metadataOptions: {
            type: Array,
            default() { return[]; },
        },
        defaultMetadata: {
            type: Array,
            required: true,
        },
        location: {
            type: [Object,Array],
            required: true,
        },
        imagePrefix: {
            type: String,
            required: true,
        },
        filenameSeperator: {
            type: String,
            default: '_',
        },
        imageFileType: {
            type: String,
            default: '.jpg',
        },
        save: {
            type: Function, // this function must be Async
            required: false,
            // can we add validation to require this function to be a promise
        },
    },
    created() {
        this.composeImageFiles();
    },
    computed: {
        headers() {
            return [
                {
                    text: 'Filename',
                    type: 'name',
                    value: 'Filename',
                    class: 'grey lighten-3',
                    disabled: true,
                },
                {
                    text: 'Media Type',
                    type: 'mediaType',
                    value: 'Media Type',
                    class: 'grey lighten-3',
                    disabled: true,
                    sortable: false,
                },
                ...this.$_metaDataTypes,
            ];
        },
        $_metaDataTypes() {
            return this.metadataTypes.map(type => {
                let text = type.type.replace(/_/g, ' ').replace(/-/g, ' ');
                text = text.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

                return {
                    text,
                    type: type.type,
                    value: type.value,
                    class: 'grey lighten-3',
                    sortable: false,
                    disabled: type.disabled || true,
                };
            });
        },
        imageGroups() {
            const groups = [];

            function addGroup(file) {
                groups.push({
                    verifiedNumber: file.verifiedImageNumber,
                    expanded: false,
                    files: [file],
                });
            }

            this.files.map(file => {
                if (file.verifiedImageNumber) {
                    if (!groups.length) {
                        addGroup(file);
                    } else {
                        if (groups.some(g => g.verifiedNumber === file.verifiedImageNumber)) {
                            groups.find(g => g.verifiedNumber === file.verifiedImageNumber).files.push(file);
                        } else {
                            addGroup(file);
                        }
                    }
                }
            });

            return groups;
        },
        ungroupedImages() {
            const arr = this.files.filter(file => {
                return file.verifiedImageNumber === undefined || file.verifiedImageNumber === null;
            });
            return arr;
        },
    },
    methods: {
        editItem(key) {
            this.headers[key].disabled = !this.headers[key].disabled;
            this.$forceUpdate();
        },
        cellDisabled(disabled, index) {
            return (index === 0 && !disabled) ? false : true;
        },
        changeCellValue(event, property, name) {
            const value = (event.currentTarget && event.currentTarget.value) ? event.currentTarget.value : event;
            const id = this.metadataOptions.find(o => o.type === property).values.find(v => v.name === value).id;
            const update = (i) => {
                const file = this.files[i];
                file.metadataValues[property] = value;
                file.metadata.map(item => {
                    if (item.type === property) {
                        item.value = value;
                        item.id = id;
                    }
                });
            }

            // when changing a value check for other files with the same verifiedNumber
            // this will change metadata for all images associated to a single placeholder
            const _file = this.files.find(file => file.name === name);
            if (_file.verifiedImageNumber) {
                this.files.forEach((file, i) => {
                    if (file.verifiedImageNumber === _file.verifiedImageNumber) {
                        update(i);
                    }
                });
            } else {
                const index = this.files.findIndex(file => file.name === name);
                update(index);
            }
        },
        async composeImageFiles() {
            const files = [];
            let verifiedImageNumber;

            for (var i = 0; i < this.files.length; i++) {
                const file = this.files[i];
                const fileNumber = await this.getFileNumber(this.getFileParts(file));
                let metadata = (this.metadataTypes.length) ? this.metadataTypes : this.defaultMetadata;
                let id;

                // NOTE: this block of logic is to support panoramic placeholdersor HD shots
                if (fileNumber && this.location.placeholders.length && (this.mediaType === 'photo_360' || this.mediaType === 'photo_hd')) {
                    verifiedImageNumber = null;

                    this.location.placeholders.map(item => {
                        if (item.mediaType.replace(/\s+/g, '').toLowerCase() === 'photo_360' || item.mediaType.replace(/\s+/g, '').toLowerCase() === 'photo_hd') {
                            const endingNumber = (item.verifiedImageNumber) ? parseInt(item.verifiedImageNumber) : null;

                            if (item.shotsPerPhoto && item.shotsPerPhoto > 1) {
                                const totalPartials = (item.imagesPerShot) ? item.shotsPerPhoto * item.imagesPerShot : item.shotsPerPhoto;
                                const startingNumber = endingNumber - totalPartials;
                                if (fileNumber >= startingNumber && fileNumber <= endingNumber) {
                                    id = item.id;
                                    verifiedImageNumber = item.verifiedImageNumber;
                                    metadata = item.metadata;
                                }
                            }
                        }
                    });
                }

                // map location metadata options to relevant metadata on files
                const metadataValues = {};
                metadata.map(data => {
                    metadataValues[data.type] = data.value
                    this.metadataOptions.forEach(option => {
                        if (data.type === option.type) {
                            data.options = option.values;
                        }
                    });
                });

                files.push({
                    metadata,
                    metadataValues,
                    fileNumber,
                    id,
                    verifiedImageNumber,
                    name: file,
                    mediaType: this.mediaType,
                    expanded: false,
                });
            }

            files.sort((a, b) => {
                if (!a.verifiedImageNumber || a.verifiedImageNumber < b.verifiedImageNumber) return -1;
                if (a.verifiedImageNumber > b.verifiedImageNumber) return 1;
                return 0;
            });

            this.$emit('update', files);
        },
        expandGroup(group) {
            this.files.map(file => {
                if (file.verifiedImageNumber === group.verifiedNumber) {
                    file.expanded = !file.expanded;
                }
            });

            this.$emit('update', this.files);
            this.$forceUpdate();
        },
        getMetaOptions(type) {
            let metaDataOptions;
            
            this.metadataOptions.map(option => {
                if (option.type === type) {
                    metaDataOptions = option.values;
                }
            });
            return metaDataOptions;
        },
        showGroupRow(expanded, index) {
            return index === 0 || expanded;
        },
        hasOptions(files, metadataValue) {
            return (files[0].metadata && files[0].metadata.find(data => data.type === metadataValue)) ? files[0].metadata.find(data => data.type === metadataValue).options : null;
        },
        getFileParts(file) {
            return (file.indexOf('_') > -1) ? file.split('_')
                : (file.indexOf(' ') > -1) ? file.split(' ')
                : file.split('-');
        },
        getFileNumber(fileParts) {
            let number;
            return new Promise(resolve => {
                fileParts.forEach(part => {
                    // handle extension
                    if (part.indexOf('.') > -1) {
                        const subparts = part.split('.');
                        subparts.forEach(subpart => {
                            if (!isNaN(parseInt(subpart))) {
                                number = parseInt(subpart);
                            }
                        });
                    }
                    if (!isNaN(part)) {
                        number = parseInt(part);
                    }
                });
                resolve(number)
            });
        },
        createCsv() {
            const data = this.files.map(file => {
                let updatedFile = {
                    'media_type':  this.mediaType,
                    'file_name': file.name,
                    'description': file.name,
                    'latitude': '',
                    'longitude': '',
                    'altitude': '',
                    'heading': '',
                    'captured_at': '',
                }

                file.metadata.forEach(data => {
                    updatedFile[data.type] = file.metadataValues[data.type]
                });

                return updatedFile;
            });

            const csvData = generateCSV(data);
            return new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
        },
        async saveData(filename, path) {
            let csv;
            if (this.generateCsv || filename) csv = this.createCsv();
            await this.save(csv, filename, path);
            this.showSaveSuccess = true;
        },
        complete() {
            this.showSaveSuccess = false;
            this.$emit('resetFiles')
        }
    },
    data() {
        return {
            showSaveSuccess: false,
            filter: null,
        }
    }
}
</script>

<style lang="scss" scoped>
    .image-metadata-wizard {
        border-top: 1px solid #ccc;
        padding-top: 2rem;
        margin-top: 2rem;

        .wizard-info-alert {
            max-width: 600px;
            min-width: 300px;
            margin: 0 auto;
        }

        &-table {
            margin-bottom: 2rem;
        }
        .metadata-table-cell {
            position: relative;

            .edit-metadata {
                position: absolute;
                top: 1.5rem;
                right: .5rem;
            }
        }
        .cell-select-box {
            width: 90%;
        }
    }
    .save-button {
        position: fixed;
        bottom: 2rem;
        right: 2rem;
        z-index: 1000;
    }
    .modal {
        z-index: 10000;
    }
</style>