<template>
  <v-list>
        <v-subheader  v-if="showHeader">Organizations:</v-subheader>
        <v-list-item
          class="pb-2 organization-list-item"
          v-for="organization in organizations" 
          :key="organization.node.name"
          @click="$emit('select', organization.node.id)"
        >
          <v-list-item-content class="pb-0">
            <v-list-item-title>
              {{organization.node.name}}
            </v-list-item-title>
            <v-list-item-subtitle>
              <span v-if="organization.node.locations && organization.node.locations.totalCount">
                Locations: {{organization.node.locations.totalCount}}
              </span>
              <span v-if="organization.node.programs && organization.node.programs.totalCount">
                | Programs: {{organization.node.programs.totalCount}}
              </span>
              <span v-if="organization.node.users && organization.node.users.totalCount">
                | Users: {{organization.node.users.totalCount}}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <v-chip color="primary" v-if="organization.node.isActive" small outlined>Active</v-chip>
            </v-list-item-subtitle>
            <v-divider class="mt-2" />
          </v-list-item-content>
            <v-menu bottom left v-if="showMenu">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        primary
                        dark
                        icon
                        v-bind="attrs"
                        v-on="on"
                        :data-q-organization-button="organization.node.id"
                    >
                        <v-icon color="blue">mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <slot name="menu" v-bind:organization="organization" />
                </v-list>
            </v-menu>
        </v-list-item>
      </v-list>
</template>

<script>
export default {
    props: {
        showHeader: {
            type: Boolean,
            defalt: true,
        },
        organizations: {
            type: Array,
            required: true,
        }
    },
    computed: {
        showMenu() {
            return this.$slots.menu || this.$scopedSlots.menu;
        }
    }
}
</script>

<style scoped lang="scss">
.organization-list-item {
  border-bottom: 1px solid #e9e9e9;
}
</style>