import { apolloClient } from '@/graphql/apollo';
import GET_LOCATION from '@/graphql/queries/location.gql';
import GET_LOCATIONS from '@/graphql/queries/locations.gql';
import GET_LOCATION_MEDIA from '@/graphql/queries/locationMedia.gql';
import GET_PROJECT from '@/graphql/queries/project.gql';
import MEDIA_DETAILS from '@/graphql/queries/media.gql';
import axios from 'axios';

const axiosOptions = {
    headers: {
        'x-client-id': 'IDS_Web_Client',
    },
};
const rubyEndpoint = process.env.VUE_APP_RUBY_API;

export default {
    namespaced: true,
    state: {
      activeLocation: null,
      locations: [],
      locationNextPage: null,
      locationMediaNextPage: null,
    },
    // you can use rootGetters via 4th argument of getters - this will give you access to other modules getters
    getters: {
      activeLocation(state) {
        return state.activeLocation;
      },
      locations(state) {
        return state.locations;
      },
    },
    actions: {
      getLocations({commit, dispatch, state}, organizationId) {
        return new Promise(resolve => {
          let variables = {id: parseInt(organizationId), first: 100};
          if (state.locationNextPage) {
            variables.after = state.locationNextPage;
          } else {
            state.locations = [];
          }

          apolloClient.query({ query: GET_LOCATIONS, variables }).then((response) => {
            state.locationNextPage = (response.data.organization.locations.pageInfo.hasNextPage)
              ? response.data.organization.locations.pageInfo.endCursor
              : null;

              if (state.locationNextPage) dispatch('getLocations', organizationId);

            commit('SET_LOCATIONS', response.data.organization.locations.edges);
            resolve();
          });
        });
      },
      async getLocationMedia({commit}, id) {
        const response = await apolloClient.query({ query: GET_LOCATION_MEDIA, variables: {id} })
        commit('SET_LOCATION_MEDIA', response.data.location);
      },
      async getProject({commit}, id) {
        const response = await apolloClient.query({ query: GET_PROJECT, variables: {id} });
        return new Promise(resolve => {
          commit('SET_ACTIVE_LOCATION_PROJECT', response.data.project);
          resolve(response.data.project);
        });
      },
      async getLocationImages({state, commit, dispatch, rootState}, id) {
        let variables = {id: rootState.organization.activeOrganization.id, first: 1000, fields: [{name: "Location:Id", values:[id]}]};
        if (state.locationMediaNextPage) {
          variables.after = state.locationMediaNextPage;
        } else {
          state.activeLocation.media = [];
        }

        const response = await apolloClient.query({ query: MEDIA_DETAILS, variables })
        state.locationMediaNextPage = (response.data.filteredImages.pageInfo.hasNextPage) ? response.data.filteredImages.pageInfo.endCursor : null;
        if (state.locationMediaNextPage) dispatch('getOrganizationMedia', rootState.organization.activeOrganization.id);

        commit('SET_LOCATION_IMAGES', response.data.filteredImages.results);
      },
      async setActiveLocation({commit}, id) {
        const response = await apolloClient.query({ query: GET_LOCATION, variables: {id} })
        return new Promise((resolve) => {
          commit('SET_ACTIVE_LOCATION', response.data.location);
          resolve();
        });
      },
      setActiveLocationPlaceholders({state}, placeholders) {
        state.activeLocation.placeholders = placeholders;
      },
      async resetLocations({state}) {
        state.locations = [];
      },
      resetActiveLocation({commit}) {
        commit('SET_ACTIVE_LOCATION', null);
      },
      setActiveLocationMetadata({commit}, data) {
        commit('SET_LOCATION_METADATA', data);
      },
      async saveActiveLocationMetadata({state}, {csvBlob, s3Path, filename, files}) {
        if (csvBlob) {
          axiosOptions.headers['Upload-Prefix'] = s3Path + '/ready/' + filename;
          const preauth = await axios.post(`${rubyEndpoint}/api/v1/uploader/request_presigned_url`, {}, axiosOptions);
          const file = new File([csvBlob], filename);

          // for some rediculous reason i can't do this with axios?
          await fetch(preauth.data.data.url, {
            method: 'PUT',
            body: file,
            headers: {
              'Content-Type': 'text/csv',
            }
          });
        } else {
          // determine what can be saved to IDS
          const batch = [];
          files.map(file => {
            // if batch does not have fileID add the object
            if (file.id && !batch.some(e => e.id === file.id)) {
              file.metadata.map(data => {
                data.value = file.metadataValues[data.type];
              });

              let childFiles = files.filter(f => f.verifiedImageNumber === file.verifiedImageNumber);
              childFiles = childFiles.map(f => { return f.name });

              batch.push({
                id: file.id,
                metadata: file.metadata,
                files: childFiles,
              });
            }
          });

          await axios.patch(`${rubyEndpoint}/api/v1/locations/${state.activeLocation.id}/metadata_images/update_batch`, {batch: batch}, axiosOptions);
        }

        return new Promise((resolve) => {
          resolve(state);
        });
      },
      uploadComplete({state}, {files, projectId, mediaType, mediaTypeId, s3}) {
        return new Promise((resolve) => {
          const data = {
            upload_details: {
              location_id: parseInt(state.activeLocation.id),
              project_id: projectId,
              s3_prefix: `${s3}`,
              media: {
                type: mediaType,
                type_id: mediaTypeId,
                count: files.length,
                files
              }
            }
          };
          axios.post(`${rubyEndpoint}/api/v1/locations/${state.activeLocation.id}/metadata_images/tus_upload_completed`, data, axiosOptions).then((response) => {
            resolve(response.data);
          });
        });
      }
    },
    mutations: {
      SET_ACTIVE_LOCATION (state, location) {
        state.activeLocation = location;
        state.activeLocation.project = null;
      },
      SET_ACTIVE_LOCATION_PROJECT (state, data) {
        state.activeLocation.project = data;
      },
      SET_LOCATION_MEDIA (state, data) {
        state.activeLocation.media = [];
        state.activeLocation.media = state.activeLocation.media.concat(data.user_photos);
        state.activeLocation.media = state.activeLocation.media.concat(data.panoramas);
      },
      SET_LOCATIONS (state, locations) {
        state.locations = state.locations.concat(locations);
      },
      SET_LOCATION_METADATA(state, data) {
        data.forEach(item => {
          state.activeLocation.placeholders.map(placeholder => {
            if (placeholder.id === item.id) {
              placeholder = item;
            }
          });
        });
      },
      SET_LOCATION_IMAGES (state, images) {
        state.activeLocation.images = [];
        state.activeLocation.images = state.activeLocation.images.concat(images);
      },
    },
};