<template>
  <v-container>
    <h1>Login</h1>

    <v-form
        ref="form"
        v-model="formValid"
        data-q-login-form
        @submit="doLogin"
    >
        <v-text-field 
            class="email-input"
            label="Email"
            v-model="form.email"
            :rules="formRules.email"
            data-q-email-field
        />
        <v-text-field 
            class="password-input"
            label="Password"
            v-model="form.password"
            :rules="formRules.password"
            type="password"
            data-q-password-field
        />
        <v-btn type="submit" color="primary" data-q-form-submit>Login</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers("authentication");


export default {
    name: 'ims-login',
    methods: {
        ...mapActions(['login']),
        async doLogin() {
            this.applyRules();
            if (this.$refs.form.validate()) {
                await this.login({authentication: this.form});
                this.$router.push({name: 'Dashboard'});
            }
        },
        applyRules() {
            this.formRules = {
                email: [
                    v => !!v || 'Email is required',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
                password: [
                    v => !!v || 'Password is required',
                ],
            };
        },
    },
    data() {
        return {
            form: {
                email: null,
                password: null,
            },
            formValid: true,
            formRules: {
                email: [],
                password: [],
            },
        }
    }
}
</script>

<style>

</style>