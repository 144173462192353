<template>
  <section>
    <span v-if="loading">
      Loading Organizations
    </span>
    <v-container v-if="hasOrganizations">
        <v-text-field 
            label="Search Organizations"
            v-model="searchQuery"
        />
        <organization-list 
            :organizations="organizationResults" 
            @select="selectOrganization" 
        />
    </v-container>

    <v-dialog v-model="viewLocation">
      <location-list :locations="locations" />
    </v-dialog>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters: mapGettersLocation, mapActions: mapActionsLocation } = createNamespacedHelpers("location");
const { mapGetters: mapGettersOrganization, mapActions: mapActionsOrganization } = createNamespacedHelpers("organization");
import OrganizationList from '@/components/organizationList.vue';
import LocationList from '@/components/locationList.vue';

export default {
  name: 'Organizations',
  components: {
      OrganizationList,
      LocationList
  },
  async created() {
      this.loading = true;
      await this.getOrganizations();
      this.loading = false;
  },
  computed: {
    ...mapGettersLocation(['locations']),
    ...mapGettersOrganization(['organizations']),
    hasOrganizations() {
      return this.organizations && this.organizations.length;
    },
    organizationResults() {
      if (!this.searchQuery) return this.organizations;
      return this.organizations.filter(org => org.node.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1);
    },
  },
  methods: {
    ...mapActionsLocation(['setActiveLocation', 'getLocations']),
    ...mapActionsOrganization(['getOrganization', 'getOrganizations']),
    async selectOrganization(id) {
      await this.getOrganization(id);
      await this.getLocations(id);
      this.viewLocation = true;
    },
    async selectLocation(id) {
      await this.setActiveLocation(id);
      this.$router.push({name: 'Location', params: {id}});
    },
  },
  data() {
    return {
      loading: false,
      searchQuery: null,
      viewLocation: false,
    }
  }
}
</script>
