import { apolloClient } from '@/graphql/apollo';
import GET_ORGANIZATIONS_LITE from '@/graphql/queries/organizationsLite.gql';
import GET_ORGANIZATION_LITE from '@/graphql/queries/organizationLite.gql';
import GET_ORGANIZATION_PROJECTS from '@/graphql/queries/organizationProjects.gql';

export default {
  namespaced: true,    
  state: {
    activeOrganization: null,
    organizations: null,
    projects: null,
  },
  getters: {
    activeOrganization(state) {
      return state.activeOrganization;
    },
    organizations(state) {
      return state.organizations;
    },
    projects(state) {
      return state.projects;
    },
  },
  actions: {
    async getOrganizations({commit, dispatch}) {
      try {
        const response = await apolloClient.query({ query: GET_ORGANIZATIONS_LITE });
        if (response.data.organizations.edges) 
          commit('SET_ORGANIZATIONS', response.data.organizations.edges);
        return response.data.organizations.edges;
      } catch (error) {
        console.error('getOrganizations error:', error)
        dispatch('authentication/logout', {}, {root: true})
      }
    },
    async getOrganization({commit, dispatch}, id) {
      try {
        const response = await apolloClient.query({ query: GET_ORGANIZATION_LITE, variables: {id, first: 100} });
        if (response.data.organization) 
          commit('SET_ACTIVE_ORGANIZATION', response.data.organization);
        return response.data.organization;
      } catch (error) {
        console.error('getOrganization error:', error)
        dispatch('authentication/logout', {}, {root: true})
      }
    },
    async getOrganizationProjects({commit, dispatch}, id) {
      try {
        const response = await apolloClient.query({ query: GET_ORGANIZATION_PROJECTS, variables: {id: id, first: 1000} });
        debugger;
        if (response.data.projects.edges) 
        commit('SET_ORGANIZATION_PROJECTS', response.data.projects.edges);
        return response.data.projects.edges;
      } catch (error) {
        console.error('getOrganizationProjects error:', error)
        dispatch('authentication/logout', {}, {root: true})
      }
    },
  }, 
  mutations: {
    SET_ORGANIZATIONS (state, data) {
      state.organizations = data;
    },
    SET_ACTIVE_ORGANIZATION (state, org) {
      state.activeOrganization = org;
    },
    SET_ORGANIZATION_PROJECTS (state, projects) {
      state.projects = projects;
    },
  }
};