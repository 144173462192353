import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

import Organizations from '../views/Organizations.vue'
import Login from '../views/Login.vue'
import Location from '../views/Location.vue'
import Organization from '../views/Organization.vue'
import Upload from '../views/Upload.vue'
import Dashboard from '../views/Dashboard.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/location/:id',
    name: 'Location',
    component: Location,
  },
  {
    path: '/upload',
    name: 'Upload',
    component: Upload,
  },
  {
    path: '/organizations',
    name: 'Organizations',
    component: Organizations,
  },
  {
    path: '/organization/:id',
    name: 'Organization',
    component: Organization,
  },
]

const router = new VueRouter({
  routes
});

router.beforeEach(async (to, from, next) => {
  if (store.getters['authentication/userToken']) {
    if (to.name === from.name) return;
    const route = (to.name === 'Login') ? {name: 'Dashboard'} : undefined;
    next(route);
  } else {
    const route = (to.name !== 'Login') ? {name: 'Login'} : undefined;
    next(route);
  }
});

export default router
