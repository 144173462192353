<template>
    <div v-if="!loaded" data-q-loading class="text-center p-4">
      Please wait while we load the page ...
    </div>
    <v-container data-q-dashboard v-else>
        <h1>Welcome to Immersion Data Solutions Web Viewer <strong>2.0</strong></h1>
        <organization-list  
            v-if="organizations" 
            :organizations="organizations" 
            @select="goToOrganization"
            data-q-organization-list>
            <template v-slot:menu="{organization}">
                <v-list-item :data-q-organization-list-item="organization.node.id">
                    <span @click.once="selectOrganization(organization.node.id)">Upload Files</span>
                </v-list-item>
            </template>
        </organization-list>
    </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('organization');
import OrganizationList from '@/components/organizationList.vue';

export default {
    components: {
        OrganizationList
    },
    async created() {
        await this.getOrganizations();
    },
    computed: {
        ...mapGetters(['organizations']),
        loaded() {
            return this.organizations;
        }
    },
    methods: {
        ...mapActions(['getOrganization', 'getOrganizations']),
        async selectOrganization(id) {
            await this.getOrganization(id);
            this.$router.push({name: 'Upload', params: {id}, query: {id}});
        },
        async goToOrganization(id) {
            await this.getOrganization(id);
            this.$router.push({name: 'Organization', params: {id}});
        }
    },
}
</script>
